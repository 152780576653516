import LookingToo from '..'
import React from 'react'
import { StaticQuery, graphql } from 'gatsby'

const queryLookingTooArticles = graphql`
  query LookingTooArticles {
    articles: allMdx(filter: { fileAbsolutePath: { regex: "/blog/" } }) {
      nodes {
        frontmatter {
          announcement
          created_at
          color
          title
          tags {
            title
          }
          img {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        slug
        id
      }
      totalCount
    }
  }
`

export const LookingTooInStaticQueryArticles = (props) => (
  <StaticQuery
    query={queryLookingTooArticles}
    render={(data) => <LookingToo data={data.articles} {...props} />}
  />
)
