import * as GA from '../../components/GA'
import App from '../../components/App'
import Footer from '../../components/Footer'
import Header from '../../components/Header'
import HelpFind from '../../components/HelpFind'
import React, { useCallback, useEffect } from 'react'
import SEO, { getSeoMedia } from '../../atoms/SEO'
import SwiperCore, { Pagination } from 'swiper'
import styled from 'styled-components'
import { ArticleInfo, ArticleTags } from '../../molecules/Article'
import { ArticleJsonLd } from 'gatsby-plugin-next-seo'
import { BreadCrumbs, BreadcrumbSEO } from '../../molecules/BreadCrumbs'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { Grid, getGridColWidth } from '../../atoms/Grid'
import { LookingTooInStaticQueryArticles } from '../../components/LookingToo/LookingTooInStaticQuery/blog'
import { MDXProvider } from '../../molecules/MDXProvider'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { graphql } from 'gatsby'
import { size } from '../../constants'
import { useLocation } from '@reach/router'

SwiperCore.use([Pagination])

const Content = styled.div`
  z-index: 3;
  position: relative;
  margin: auto;
  ${getGridColWidth(7)}

  @media (max-width: ${size.lg}) {
    ${getGridColWidth(9)}
  }

  @media (max-width: ${size.md}) {
    width: 100%;
  }
`

const RenderTOC = (toc) => {
  if (!toc.items) {
    return
  }

  return (
    <ol>
      {toc.items.map((item) => (
        <>
          <li className="material__headline">
            <a href={item.url}>{item.title}</a>
          </li>
        </>
      ))}
    </ol>
  )
}

const toValidDateFormat = (date) => date.split('.').reverse().join('/')

const Article = ({ data }) => {
  const isDocument = () => typeof document !== 'undefined'
  const { pathname } = useLocation()

  let root

  useEffect(() => {
    if (isDocument()) {
      root = document.querySelector('.root')
    }
  }, [])

  const {
    contents,
    title,
    author,
    created_at: createdAt,
    description,
    img,
    tags
  } = data.mdx.frontmatter

  const { tableOfContents, body, id } = data.mdx

  useEffect(() => {
    root.scrollTo(0, 0)
    root.classList.remove('root_fixed')
  }, [root])

  const handleClickSeeAlso = useCallback(() => {
    GA.Event.SeeAlso('OpenArticle')
  }, [])

  return (
    <>
      <App>
        <SEO
          children={
            <>
              <ArticleJsonLd
                datePublished={new Date(
                  toValidDateFormat(createdAt)
                ).toISOString()}
                description={description}
                headline={title}
                images={getSeoMedia([
                  {
                    pathname: getImage(img).images.fallback.src,
                    alt: 'article'
                  }
                ])}
                overrides={{
                  '@type': 'CreativeWork'
                }}
                url={`${process.env.GATSBY_SITEURL}${pathname}`}
              />
              <BreadcrumbSEO pageName={title} />
            </>
          }
          article={{
            headline: title,
            description: description,
            publishedTime: new Date(toValidDateFormat(createdAt)).toISOString(),
            modifiedTime: new Date(toValidDateFormat(createdAt)).toISOString(),
            tags: data.mdx.frontmatter.tags.map(({ title }) => title)
          }}
          description={`${description} - самая актуальная и полезная информация на сайте youtalk.ru`}
          images={getSeoMedia([
            { pathname: getImage(img).images.fallback.src, alt: 'article' }
          ])}
          title={`${title} | YouTalk`}
          type="Article"
        />
        <Header />
        <BreadCrumbs pageName={title} />
        <main className="main">
          <section className="material">
            <Grid>
              <Content>
                <ArticleInfo
                  author={author}
                  createdAt={createdAt}
                  tags={tags}
                  title={title}
                />
                <div className="material__video-img-wrap">
                  <GatsbyImage alt={title} image={getImage(img)} />
                </div>
                {contents === 'true' && (
                  <nav>
                    <div className="material__contents">Содержание</div>
                    <div className="material__content-wrapper">
                      {RenderTOC(tableOfContents)}
                    </div>
                  </nav>
                )}
                <div className="material__content b-content">
                  <MDXProvider>
                    <MDXRenderer>{body}</MDXRenderer>
                  </MDXProvider>
                </div>
                <ArticleTags tags={tags} />
              </Content>
            </Grid>
          </section>
          <HelpFind
            className="help-find_bordered"
            onClick={GA.useNewOpenForm('article')}
          />
          <LookingTooInStaticQueryArticles
            handleClick={handleClickSeeAlso}
            id={id}
            type="articles"
          />
        </main>
        <Footer />
      </App>
    </>
  )
}

export default Article

export const query = graphql`
  query Article($id: String) {
    mdx(id: { eq: $id }) {
      frontmatter {
        created_at(formatString: "DD.MM.YYYY")
        announcement
        description
        contents
        title
        author
        tags {
          title
        }
        img {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      tableOfContents
      body
      id
    }
  }
`
